import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/smile-again.scss"

const SmileAgainProgram = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 2000
  }
  return (
    <Layout>
      <SEO
        title="Smile Again Program"
        description="It is time for a brand new, healthy, beautiful smile at  Oral Surgery & Dental Implant Specialists of Cincinnati."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-hero-image"
            alt="smile again banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-hero-image-mobile"
            alt="smile again banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity to Smile Again</h1>

            <p>
              Oral Surgery & Dental Implant Specialists of Cincinnati is proud
              to announce its Smile Again program. This life-changing program
              provides one deserving recipient with a complete smile makeover
              for free.
            </p>

            <p>
              The ideal candidate has multiple missing or failing teeth and
              cannot afford restorative treatment. After the submission period,
              we will select one recipient to receive a{" "}
              <Link
                to="/full-arch-restoration/"
                title="Learn more about full-arch restoration">
                full-arch restoration
              </Link>
              . We encourage anyone in our community who is dreaming of a
              healthy, beautiful new smile to apply!
            </p>

            <h2 style={{ fontSize: "24px" }}>Full-Arch Restoration</h2>
            <p>
              Full-arch restoration is a healthy and effective way to replace
              many missing teeth. The procedure works by securing a full
              prosthesis in the mouth using as few as four dental implants. The
              result is a complete smile restoration that looks and feels
              natural.
            </p>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container">
            <div className="sas__brackets">
              <div className="sas__about-grid">
                <div>
                  <p className="sas__subheading">How To Apply</p>
                  <ul>
                    <li>Complete the short application.</li>
                    <li>Upload photos of your smile and teeth.</li>
                    <li>Submit your application by October 30, 2024.</li>
                  </ul>
                </div>
              </div>
            </div>

            <a
              className="sas__submit-btn"
              href="https://secureform.seamlessdocs.com/f/429a4pz3cczy"
              title="Follow the link to submit your application"
              target="_blank">
              SUBMIT APPLICATION
            </a>
          </div>
        </div>

        {/* <div className="sas__section">
          <div className="sas__container small">
            <Slider {...settings}>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-1"
                  alt="program recipient"
                />
              </div>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-2"
                  alt="program recipient"
                />
              </div>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-3"
                  alt="program recipient"
                />
              </div>
              <div>
                <video
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  controls>
                  <source
                    src="https://res.cloudinary.com/nuvolum/video/upload/v1699992734/CIOS/DEV/smile-again-recipient-4.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Slider>
          </div>
        </div> */}

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              Stay tuned to this page,{" "}
              <a
                href="https://www.facebook.com/cincinnatioralsurgeons"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/cincinnatioralsurgeons/?hl=en"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              for program updates!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-cincinnati-oh/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileAgainProgram
